<button *ngIf="!validateForm"
  pButton
  [type]="defaultType"
  [ngClass]="customClasses"
  class="fdl-button button-{{buttonType}} button-{{buttonSize}}"
  [label]="label"
  [loading]="isLoading"
  [disabled]="isDisabled"
  [pTooltip]="tooltip"
  [tooltipPosition]="tooltipPosition"
  iconPos="right"
  (click)="onClick($event)"
  >

  <div *ngIf="customPrefixTemplate">
    <ng-container *ngTemplateOutlet="customPrefixTemplate"></ng-container>
  </div>

  <fdl-svg-sprite
    *ngIf="icon" 
    [icon]="icon"
    [containerClasses]="iconContainerClasses"
    [classes]="iconClasses">
  </fdl-svg-sprite>
</button>

<button *ngIf="validateForm"
  pButton
  [type]="defaultType"
  [ngClass]="customClasses"
  class="fdl-button button-{{buttonType}} button-{{buttonSize}}"
  [label]="label"
  [isLoading]="isLoading"
  [disabled]="isDisabled"
  iconPos="right"
  (click)="onClick($event)"
  [pTooltip]="tooltip"
  [tooltipPosition]="tooltipPosition"
  [loadingText]="loadingText"
  [formToValidate]="formToValidate"
  [formElement]="formElement"
  fylAppFormValidationButtonLoader
  >

  <fdl-svg-sprite
    *ngIf="icon" 
    [icon]="icon"
    [containerClasses]="iconContainerClasses"
    [classes]="iconClasses">
  </fdl-svg-sprite>
</button>