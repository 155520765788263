export const environment = {
    production: true,
    GOOGLE_CLIENT_ID: '62198175306-h477rqb0lmglnlolib9pn2m9pjudrm2h.apps.googleusercontent.com',
    IP_FIND_KEY: '89faecdb-c41e-4ba0-8eb3-6dd1a731eddc',
    ROOT_URL: 'https://us1.fylehq.com',
    APP_URL: 'https://ae.fyle.co/app',
    APP_ROUTER_URL: 'https://accounts.fylehq.com',
    ROUTER_URL: 'https://accounts.fylehq.com',
    SENTRY_DSN: 'https://4a085c2fd6d64406821ecc300cc7e42b@o341960.ingest.sentry.io/3554020',
    SEND_ERROR_TO_SENTRY: true,
    SENTRY_TRACES_SAMPLE_RATE: 0.1,
    ENVIRONMENT: 'prod',
    RELEASE: '0da7e9797838a2e57ab2741cd0b2e2fb97638837',
    SEGMENT_SOURCE_ID: 'HLAIx2uwpdglrMKhUV9rREAr9ADpeJ48',
    TARGET: 'fyle',
    FW_ENVIRONMENT: 'undefined',
    GOOGLE_MAPS_API_KEY: 'AIzaSyAN1rmM-KdfNimjKxUwRkQB2ASpY6Czrvc',
    DWOLLA_ENVIRONMENT: 'prod',
    LAUNCH_DARKLY_CLIENT_ID: '61fb848da00c1016c73fbe55',
  };