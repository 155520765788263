<div class="auth-container">
  
  <div class="card-container">
    <app-header></app-header>
    <div class="auth-card tw-mb-0">
      <app-loading-overlay *ngIf="showOverlay">
        <span>Checking your Google credentials</span>
      </app-loading-overlay>

      <div *ngIf="errorState" class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <app-svg-sprite 
          class="icon-bg tw-flex tw-items-center tw-justify-center" 
          icon="danger-outline" 
          classes="tw-w-16-px tw-h-16-px tw-fill-fire-engine-red">
        </app-svg-sprite>

        <h3 class="header">{{header}}</h3>

        <div class="description">
          <ng-container *ngFor="let description of descriptions">
            <div>{{ description }}</div>
          </ng-container>
        </div>

        <!-- Don't show prompt to signup for demo if a new user is trying to sign up from a non-fyle env -->
        <div class="action-alternate">
          <div class="alternate-header">{{ alternateHeader }}</div>
          <div *ngIf="!isNotAnExistingEmail" class="alternate-description">
            <span class="action" (click)="openSignInPage()">Sign In</span> using another account
          </div>

          <div *ngIf="isNotAnExistingEmail" class="alternate-description">
            <span class="action" (click)="openSignupDemoPage()">Sign Up</span> for a demo
          </div>

        </div>
      </div>
    </div>
  </div>

  <footer *ngIf="errorState" class="footer text-center">
    Never lose track
  </footer>

</div>
