<div class="active-org-card">
  <div class="card-container">
    <div class="card-header">
      Active Organization
    </div>
    <div *ngIf="!isLoading" pTooltip="Continue with Active Org"
      tooltipPosition="bottom" tooltipEvent="hover" tooltipZIndex="100"
      class="org-info-container tw-relative" (click)="onOrgSelection()">
      <div class="flex-row-center">
        <div class="currency-icon">
          <span class="currency">{{ activeOrg.currency }}</span>
          <app-svg-sprite class="check-icon flex-row-center" icon="check" classes="icon-org-button-badge tw-fill-white"></app-svg-sprite>
        </div>
        <div class="org-details">
          <div class="org-header">{{ activeOrg.name }}</div>
          <div class="org-domain">{{ activeOrg.domain }}</div>
        </div>
      </div>
      <div *ngIf="isPrimary" class="primary-text">Primary Org</div>
    </div>

    <div *ngIf="isLoading" class="org-info-container" >
      <div class="flex-row-center">
        <div class="currency-icon-shimmer">
          <app-shimmers [height]="52" class="currency-icon-shimmer"></app-shimmers>
          <app-shimmers class="check-icon-shimmer flex-row-center"></app-shimmers>
        </div>
        <div class="org-details">
          <app-shimmers class="org-header" [height]="18" [width]="200"></app-shimmers>
          <app-shimmers class="org-domain" [height]="18" [width]="120"></app-shimmers>
        </div>
      </div>
    </div>
  </div>
</div>